import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form/contact-form"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"

import Header from "../components/header"
import Section from "../components/section"
import Map from "../components/map/map"
import styled from "styled-components"

import MapStyle from "../components/map/style.json"

import { respondTo } from "../_respondTo"
import { palette } from "../_variables"

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;

  &.contact {
    justify-content: center;
    margin: 1em 0 2em 0;
  }

  ${respondTo.md`
    flex-direction:row;
  `}
`

const FlexItem = styled.div`
  &.map-container {
    height: 50vh;
  }

  &.contact {
    margin-bottom: 0.75em;
  }

  ${respondTo.md`

    flex: 1 1 50%;


    &.map-container{
      padding:0.75rem;
      height: auto;
    }
  `}
`

const ContactWrapper = styled.div`
  text-align: center;

  & > h3 {
    color: ${palette.primary};
    margin-bottom: 0;
  }
`
const ContactIcon = styled.span`
  color: ${palette.primary};
  margin-right: 0.5em;
`

const ContactUsPage = props => (
  <Layout>
    <SEO title="Contact Us" />

    <BackgroundImage
      fluid={props.data.contactUsImage.childImageSharp.fluid}
      alt="A telephone"
    >
      <Header headerText="Contact Us"></Header>
    </BackgroundImage>

    <Section myClass="wide">
      <ContactWrapper>
        <h3>True Wealth Management</h3>
        <br />
        <FlexRow className={"contact"}>
          <FlexItem className={"contact"}>
            <ContactIcon>
              <FaBuilding />
            </ContactIcon>{" "}
            Stonyroyd House, 8 Cumberland Road, Leeds, LS6 2EF
          </FlexItem>
          <FlexItem className={"contact"}>
            <ContactIcon>
              <FaPhone />
            </ContactIcon>{" "}
            0113 224 2800
          </FlexItem>
          <FlexItem className={"contact"}>
            <ContactIcon>
              <FaEnvelope />
            </ContactIcon>{" "}
            <a href="mailto:info@truewm.com">info@truewm.com</a>
          </FlexItem>
        </FlexRow>
      </ContactWrapper>

      <FlexRow>
        <FlexItem>
          <ContactForm></ContactForm>
        </FlexItem>

        <FlexItem className={"map-container"}>
          <Map
            id="myMap"
            options={{
              center: { lat: 53.812036, lng: -1.558029 },
              zoom: 13,
              styles: MapStyle,
            }}
            onMapLoad={map => {
              var marker = new window.google.maps.Marker({
                position: { lat: 53.817197, lng: -1.564544 },
                map: map,
                title: "True Wealth Management",
              })
            }}
          />
        </FlexItem>
      </FlexRow>
    </Section>
  </Layout>
)

export default ContactUsPage

export const pageQuery = graphql`
  query {
    contactUsImage: file(relativePath: { eq: "contact-us.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
